const Layout = () => import('@/containers/index.vue')

export default {
    path: '/dynamic',
    component: Layout,
    redirect: '/dynamic',
    name: 'dynamic',
    meta: {
        title: '志愿消防',
    },
    children: [
        {
            path: '',
            name: 'dynamic',
            component: () => import('@/pages/dynamic/index.vue'),
            meta: {
                title: '志愿消防',
                activeMenu: '/dynamic',
            },
        },
    ]
}
