import api from '@/api'
import {useAccountOutsideStore} from "@/store/account";

/**
 * 获取用户状态量
 */
const accountStore = useAccountOutsideStore();

/**
 * 用户相关接口
 * @type {{}}
 */
export const accountApi = {
    /**
     * 登陆
     * @param data
     * @returns {Promise<unknown>}
     */
    login(data) {
        return new Promise((resolve, reject) => {
            api.post('account/login_username.php?method=login', data).then(res => {
                accountStore.setAccount(res.data);
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        });
    },

    /**
     * 获取登陆状态
     * @returns {Promise<unknown>}
     */
    status() {
        return new Promise((resolve, reject) => {
            api.post('account/login_status.php?method=login_status').then(res => {
                // 设置用户信息
                accountStore.setAccount(res.data);
                // 返回结果
                resolve(res.data["login_status"])
            }).catch(error => {
                // 清除用户信息
                accountStore.clearAccount();
                reject(false)
            })
        })
    },

    /**
     * 注销登陆
     * @returns {Promise<unknown>}
     */
    logout() {
        return new Promise((resolve, reject) => {
            api.post('account/logout.php?method=logout').then(res => {
                // 设置用户信息
                accountStore.clearAccount(res.data);
                // 返回结果
                resolve(res)
            }).catch(error => {
                // 清除用户信息
                accountStore.clearAccount();
                reject(error)
            })
        })
    },
}
