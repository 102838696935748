import {defineStore} from 'pinia'
import {piniaStore} from '@/store'
import {useRouteOutsideStore} from "@/store/route";
import {useMenuOutsideStore} from "@/store/menu";

/**
 * 使用路由状态量
 */
const routeStore = useRouteOutsideStore();

/**
 * 使用菜单状态量
 */
const menuStore = useMenuOutsideStore();

/**
 * 记录用户信息，全局共享数据
 */
export const useAccountStore = defineStore(
    // 唯一ID
    'account',
    {
        state: () => ({
            /**
             * 用户ID
             */
            _userId: null,

            /**
             * 用户名
             */
            _username: '',

            /**
             * 昵称
             */
            _nickname: '',

            /**
             * 在线状态
             */
            _status: false,
        }),

        getters: {
            /**
             * 权限
             * @returns {any}
             */
            permissions() {
                return [];
            },
        },

        actions: {

            /**
             * 设置用户
             * @param data
             */
            setAccount(data) {
                this._status = data["login_status"];
                this._userId = data["user_id"];
                this._username = data["username"];
                this._nickname = data["nickname"];
            },

            /**
             * 清除用户
             */
            clearAccount() {
                this._status = false;
                this._userId = null;
                this._username = null;
                this._nickname = null;
                // 清除当前用户路由
                routeStore.removeRoutes();
                // 默认选中第一个菜单
                menuStore.setActived(0)
            },
        }
    }
)

export function useAccountOutsideStore() {
    return useAccountStore(piniaStore)
}

