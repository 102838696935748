import {defineStore} from 'pinia'
import {piniaStore} from '@/store'
import {deepClone, resolveRoutePath} from '@/util'
import {useAccountOutsideStore} from './account'
import {useRouteOutsideStore} from './route'

/**
 * 获取最深路径
 * @param routes
 * @param rootPath
 * @returns {string|*}
 */
const getDeepestPath = (routes, rootPath = '') => {
    let retnPath
    if (routes.meta.sidebar !== false) {
        retnPath = resolveRoutePath(rootPath, routes.path)
    } else if (routes.children) {
        if (
            routes.children.some(item => {
                return item.meta.sidebar !== false
            })
        ) {
            for (let i = 0; i < routes.children.length; i++) {
                if (routes.children[i].meta.sidebar !== false) {
                    retnPath = getDeepestPath(routes.children[i], resolveRoutePath(rootPath, routes.path))
                    break
                }
            }
        } else {
            retnPath = getDeepestPath(routes.children[0], resolveRoutePath(rootPath, routes.path))
        }
    } else {
        retnPath = resolveRoutePath(rootPath, routes.path)
    }
    return retnPath
}

/**
 * 菜单权限
 * @param permissions
 * @param route
 * @returns {*}
 */
const hasPermission = (permissions, route) => {
    let isAuth = false
    if (route.meta && route.meta.auth) {
        isAuth = permissions.some(auth => {
            if (typeof route.meta.auth == 'string') {
                return route.meta.auth === auth
            } else {
                return route.meta.auth.some(routeAuth => {
                    return routeAuth === auth
                })
            }
        })
    } else {
        isAuth = true
    }
    return isAuth
}

/**
 * 过滤异步菜单
 * @param menus
 * @param permissions
 * @returns {[]}
 */
const filterAsyncMenus = (menus, permissions) => {
    const res = []
    menus.forEach(menu => {
        let tmpMenu = deepClone(menu)
        if (hasPermission(permissions, tmpMenu)) {
            if (tmpMenu.children) {
                tmpMenu.children = filterAsyncMenus(tmpMenu.children, permissions)
                tmpMenu.children.length && res.push(tmpMenu)
            } else {
                res.push(tmpMenu)
            }
        }
    })
    return res;
}

/**
 * 菜单状态量
 */
export const useMenuStore = defineStore(
    // 唯一ID
    'menu',
    {
        state: () => ({
            /**
             * 菜单列表
             */
            menus: [],

            /**
             * 当活动中项
             */
            actived: 0
        }),
        getters: {
            /**
             * 完整导航数据
             * @returns {*}
             */
            allMenus() {
                let menus;
                const routeStore = useRouteOutsideStore();
                menus = [{children: []}];
                routeStore.routes.map(item => {
                    menus[0].children.push(...item.children)
                });
                return menus
            },

            /**
             * 次导航
             */
            sidebarMenus() {
                return this.allMenus.length > 0 ? this.allMenus[this.actived].children : []
            },

            /**
             * 次导航里第一个导航的路径
             */
            sidebarMenusFirstDeepestPath() {
                return this.allMenus.length > 0 ? getDeepestPath(this.sidebarMenus[0]) : '/'
            },
        },
        actions: {
            /**
             * 生成导航（前端生成）
             * @returns {Promise<unknown>}
             */
            generateMenusAtFront() {
                return new Promise(async resolve => {
                    let accessedMenus;
                    const permissions = await useAccountOutsideStore().permissions;
                    accessedMenus = filterAsyncMenus(menu, permissions)
                    this.menus = accessedMenus.filter(item => item.children.length !== 0);
                    resolve()
                })
            },
            // 切换主导航
            setActived(data) {
                if (typeof data === 'number') {
                    // 如果是 number 类型，则认为是主导航的索引
                    this.actived = data
                } else {
                    // 如果是 string 类型，则认为是路由，需要查找对应的主导航索引
                    this.allMenus.map((item, index) => {
                        if (
                            item.children.some(r => {
                                return data.indexOf(r.path + '/') === 0 || data === r.path
                            })
                        ) {
                            this.actived = index;
                        }
                    })
                }
            }
        }
    }
)

export function useMenuOutsideStore() {
    return useMenuStore(piniaStore)
}
